import React, { useState, useEffect, useRef } from 'react';


const Header = () => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);

  const scrollTo = (id) => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth' });
    }
    setIsMenuOpen(false);
  };

  return (
    <header className="fixed top-0 left-0 right-0 h-16 bg-white z-50 shadow-md">
      <div className="container mx-auto px-4 h-full flex items-center justify-between">
        <a href="https://heritage.enterprises" className="flex items-center">
          <img 
            src="/images/logo.svg" 
            alt="Heritage Enterprises Logo" 
            className="h-8 w-auto"
            style={{ maxWidth: '200px' }}
          />
        </a>
        <nav className="hidden md:block">
          <ul className="flex space-x-6">
            <li><button onClick={() => scrollTo('what-we-do')} className="text-gray-600 hover:text-gray-900">Our Story</button></li>
            <li><button onClick={() => scrollTo('who-we-are')} className="text-gray-600 hover:text-gray-900">About Us</button></li>
            <li><button onClick={() => scrollTo('who-we-work-with')} className="text-gray-600 hover:text-gray-900">Partners</button></li>
            <li><button onClick={() => scrollTo('contact')} className="text-gray-600 hover:text-gray-900">Contact</button></li>
          </ul>
        </nav>
        <button onClick={() => setIsMenuOpen(!isMenuOpen)} className="md:hidden text-gray-600 hover:text-gray-900">
          <svg className="w-6 h-6" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M4 6h16M4 12h16M4 18h16" />
          </svg>
        </button>
      </div>
      {isMenuOpen && (
        <nav className="md:hidden bg-white shadow-md">
          <ul className="py-2">
            <li><button onClick={() => scrollTo('what-we-do')} className="block w-full text-left px-4 py-2 text-gray-600 hover:bg-gray-100">Our Story</button></li>
            <li><button onClick={() => scrollTo('who-we-are')} className="block w-full text-left px-4 py-2 text-gray-600 hover:bg-gray-100">About Us</button></li>
            <li><button onClick={() => scrollTo('who-we-work-with')} className="block w-full text-left px-4 py-2 text-gray-600 hover:bg-gray-100">Partners</button></li>
            <li><button onClick={() => scrollTo('contact')} className="block w-full text-left px-4 py-2 text-gray-600 hover:bg-gray-100">Contact</button></li>
          </ul>
        </nav>
      )}
    </header>
  );
};

const IntroSection = () => (
  <section className="fixed top-16 left-0 right-0 bg-white h-[calc(95vh-4rem)] overflow-hidden">
    <div className="container mx-auto h-full flex flex-col md:flex-row items-center px-4">
      <div className="w-full md:w-1/2 mb-8 md:mb-0 md:pr-8">
        <h2 className="text-4xl sm:text-5xl md:text-6xl mt-8 lg:mt-0 font-montserrat font-bold leading-tight mb-4">We Build Enduring Businesses That Stand the Test of Time</h2>
        <p className="text-lg sm:text-xl mt-4">Heritage acquires and invests in small, well-run businesses</p>
      </div>
      <div className="w-full md:w-1/2 h-64 md:h-full flex items-center justify-center overflow-hidden">
        <div className="relative inline-block">
          <video 
            className="max-w-full max-h-full object-contain"
            autoPlay 
            muted 
            playsInline
          >
            <source src="/videos/output_color_corrected.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>          
{/*           <div className="absolute inset-0 pointer-events-none" style={{
            background: `
              radial-gradient(
                circle,
                transparent 60%,
                rgba(255, 255, 255, 0.3) 70%,
                rgba(255, 255, 255, 0.7) 80%,
                white 90%
              )
            `
          }}></div> */}
        </div>
      </div>
    </div>
  </section>
);

const WhatWeDoSection = () => (
  <section id="what-we-do" className="bg-white pb-16">
    <div className="container mx-auto px-4 -mt-16">
      <div className="bg-gray-900 rounded-lg p-8 md:py-12 md:px-24 shadow-lg">
        <h2 className="text-3xl font-medium mb-8 text-white">Our Story</h2>
        <p className="text-white font-extralight text-base leading-relaxed pt-4">
          At Heritage, we believe some of the best-run businesses in America are small and family-owned enterprises. These companies, often overlooked by traditional investors, embody the spirit of entrepreneurship that defines our nation's economic success.        </p>
          <p className="text-white font-extralight text-base leading-relaxed pt-4">
          We've also noticed a concerning trend: when owners of these well-run businesses are ready to move on, there's often no one to take the reins. This creates a missed opportunity for the businesses, their employees, and their communities.        </p>
          <p className="text-white font-extralight text-base leading-relaxed pt-4">
          Heritage was founded to bridge this gap. We're not just buyers; we're partners committed to growing these companies through strategic application of technology and operational expertise. Our team brings over 50 years of combined experience from Silicon Valley and Wall Street, offering a unique perspective on unlocking potential in overlooked businesses.</p>
          <p className="text-white font-extralight text-base leading-relaxed pt-4">
          We seek partnerships with owners who have built sustainable companies and are looking for someone to take their life's work to the next level. Our approach is hands-on and pragmatic, focusing on enhancing what works and carefully integrating new technologies to drive growth.</p>
          <p className="text-white font-extralight text-base leading-relaxed pt-4">
          In a world chasing the next big thing, we see immense value in the tried and true. We're preserving legacies while creating new opportunities, investing not just in businesses, but in the future of American entrepreneurship.</p>
          <p className="text-white font-extralight text-base leading-relaxed pt-4">
          Join us in writing the next chapter of these American success stories. Together, we can build a heritage that will last for generations to come.</p>
      </div>
    </div>
  </section>
);

const OutlineDrawingRevealBox = ({ word }) => {
  const [isVisible, setIsVisible] = useState(false);
  const boxRef = useRef(null);

  useEffect(() => {
    const observer = new IntersectionObserver(
      ([entry]) => {
        if (entry.isIntersecting) {
          setIsVisible(true);
        }
      },
      { threshold: 0.5 }
    );

    if (boxRef.current) {
      observer.observe(boxRef.current);
    }

    return () => {
      if (boxRef.current) {
        observer.unobserve(boxRef.current);
      }
    };
  }, []);

  return (
    <div ref={boxRef} className="relative w-full h-64">
      <svg
        className="absolute inset-0 w-full h-full"
        viewBox="0 0 100 100"
        preserveAspectRatio="none"
      >
        <rect
          x="0"
          y="0"
          width="100"
          height="100"
          fill="white"
          stroke="black"
          strokeWidth="1"
          className={isVisible ? 'animate-draw' : ''}
          style={{
            strokeDasharray: 400,
            strokeDashoffset: 400,
            transition: 'stroke-dashoffset 2s ease-in-out'
          }}
        />
      </svg>
      <div 
        className={`absolute inset-0 flex items-center justify-center text-8xl font-medium text-slate-600 transition-opacity duration-1000 ${isVisible ? 'opacity-100 delay-2000' : 'opacity-0'}`}
        style={{
          wordBreak: 'break-word',
          textAlign: 'center',
          lineHeight: '1',
          padding: '1rem'
        }}
      >
        {word}
      </div>
    </div>
  );
};  

const WhoWeWorkWithSection = () => {
  const partners = [
    {
      title: "We prioritize sub-$5m businesses with strong cash flow",
      word: "< $5M"
    },
    {
      title: "We focus on businesses with operations in the New York and New Jersey area",
      word: "NY & NJ"
    },
    {
      title: "We work with all industries, with a focus on consumer, retail, healthcare, distribution, and leasing & rental",
      word: "All Sectors"
    }
  ];

  return (
    <section id="who-we-work-with" className="bg-white py-16">
      <div className="container mx-auto px-4">
        <h2 className="text-6xl font-medium mb-24 text-center">Who We Work With</h2>
        <div className="space-y-16">
          {partners.map((partner, index) => (
            <React.Fragment key={index}>
              <div className={`flex flex-col md:flex-row items-center ${index % 2 === 1 ? 'md:flex-row-reverse' : ''}`}>
                <div className="w-full md:w-1/2 mb-8 md:mb-0 text-center">
                  <h3 className="text-4xl font-light mb-4 mx-auto md:w-3/5 text-center">{partner.title}</h3>
                  <p className="text-gray-700 font-extralight text-sm leading-relaxed">{partner.description}</p>
                </div>
                <div className="w-full md:w-1/2 flex justify-center items-center">
                  <div className="w-full max-w-md">
                    <OutlineDrawingRevealBox word={partner.word} />
                  </div>
                </div>
              </div>
              {index < partners.length - 1 && (
                <div className="w-full h-px bg-gray-200 my-16"></div>
              )}
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

const WhoWeAreSection = () => {
  const founders = [
    {
      name: "Randy Chao",
      photo: "/images/john-doe.jpg",
      title1: "Finance",
      bio: "Randy is an experienced finance professional and trusted strategic advisor.  With over 20 years of experience advising entrepreneurs, owners, and boards of directors, he has completed more than 100 transactions representing in excess of $15 billion in total value.  Most recently as a managing director at a premier investment bank, he led a team specialized in mergers & acquisitions, growth equity and debt financing."
    },
    {
      name: "Michael Fives",
      photo: "/images/fives.svg",
      title1: "Technology",
      bio: "Michael brings over 20 years of hands-on experience in technology and company building. A successful technology startup entrepreneur, Michael's career also spans substantial roles at blue chip technology companies such as Microsoft and Samsung. As a technology startup founder he has built  companies, backed by some of Silicon Valley's top VC firms, that delivered cutting edge data and machine learning systems to large enterprise customers."
    }
  ];

  return (
    <section id="who-we-are" className="bg-slate-100 pt-24 pb-36">
      <div className="container mx-auto px-4">
        <h2 className="text-6xl font-medium mb-24 text-center">About Us</h2>
        <div className="grid md:grid-cols-2 gap-12">
          {founders.map((founder, index) => (
            <div key={index} className="flex flex-col items-center md:items-start">
              <div className="flex items-center mb-4">
                <img src={founder.photo} alt={`${founder.name} photo`} className="w-24 h-24 rounded-full mr-4" />
                <div>
                  <h3 className="text-4xl font-medium">{founder.name}</h3>
                  <p className="text-gray-500 font-normal text-lg">{founder.title1}</p>
                </div>
              </div>
              <p className="text-gray-800 leading-relaxed text-lg font-light">
                {founder.bio}
              </p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

const ContactSection = () => {
  const [email, setEmail] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle form submission here
    console.log('Form submitted', { email, message });
  };

  return (
    <>
      <section id="contact" className="bg-white py-32"> {/* Changed py-16 to pt-16 pb-32 */}
        <div className="container mx-auto px-4">
          <h2 className="text-6xl font-medium mb-24 text-center">Contact</h2>
          <div className="flex flex-col md:flex-row md:space-x-8">
            <div className="md:w-1/2 mb-8 md:mb-0">
              <h2 className="text-4xl font-medium mb-4">Get in touch</h2>
              <p className="text-gray-800 leading-relaxed text-lg font-light">
                We'd love to hear from you. Send us a message and we'll respond as soon as possible.
              </p>
            </div>
            <div className="md:w-1/2">
              <form onSubmit={handleSubmit}>
                <div className="mb-6">
                  <label htmlFor="email" className="block text-gray-700 text-sm font-medium mb-2">
                    Your Email Address
                  </label>
                  <input
                    type="email"
                    id="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="w-full border-b-2 border-gray-300 focus:border-blue-500 focus:outline-none py-2"
                    required
                  />
                </div>
                <div className="mb-6">
                  <label htmlFor="message" className="block text-gray-700 text-sm font-medium mb-2">
                    Your Message
                  </label>
                  <textarea
                    id="message"
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    className="w-full border-b-2 border-gray-300 focus:border-blue-500 focus:outline-none py-2 resize-none overflow-hidden"
                    rows="1"
                    required
                    onInput={(e) => {
                      e.target.style.height = 'auto';
                      e.target.style.height = e.target.scrollHeight + 'px';
    }}
                  ></textarea>
                </div>
                <button type="submit" className="bg-slate-950 text-white font-bold py-2 px-4 rounded-lg hover:bg-slate-500 transition duration-300">
                  Send Message
                </button>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

const Footer = () => (
  <footer className="bg-black text-white py-8">
    <div className="container mx-auto px-4">
      <p>&copy; 2024 Heritage Enterprises. All rights reserved.</p>
    </div>
  </footer>
);

const App = () => {
  const [scrollY, setScrollY] = useState(0);

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY);
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  const introHeight = 'calc(95vh - 4rem)'; // 95% of viewport height minus header height

  return (
    <div className="min-h-screen">
      <Header />
      <IntroSection />
      <div className="relative">
        <div style={{ height: introHeight }} /> {/* Transparent container */}
        <div className="bg-white">
          <WhatWeDoSection />
          <WhoWeWorkWithSection /> {/* New section added here */}
          <WhoWeAreSection />
          <ContactSection />
          <Footer />
        </div>
      </div>
    </div>
  );
};

export default App;